.footer {
    width: 100%;
    background-color: #546d73;
    padding: 15px 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

.footer-links {
    margin-right: 20px;
    color: #fff;
    text-decoration: none;
}

.footer-links span {
    color: #fff;
}

.footer-social a {
    margin-left: 20px;
}

.footer-social img {
    height: 24px; /* Adjust size as needed */
    width: 24px;
}
