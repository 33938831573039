body {
  background-color: #dce1e3;
  color: #306078;
  height: 100vh;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column; /* stack children vertically */
}

.footer {
  width: 100%;
  text-align: left;
}
