.banner {
    position: fixed;  /* Fix the banner at the top */
    top: 0;           /* Position it right at the top */
    left: 0;          /* Stretch it to the left edge */
    width: 100%;      /* Full width */
    background-color: rgba(220, 225, 227, 0.4);  /* generated with https://rgbacolorpicker.com/hex-to-rgba for opacity */
    padding: 40px 0; /* Adjust the top and bottom padding */
    display: flex;
    justify-content: flex-start;  /* Align items to the left */
    align-items: center;
    z-index: 1000;    /* Ensure the banner is above other elements */
    font-family: 'Montserrat', sans-serif;
}

.banner-button {
    color: #306078;
    background-color: rgba(220, 225, 227, 0);
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: font-weight 0.3s;
    font-size: 18px; /* Adjust to your preferred size */
}

.banner-button.active {
    font-weight: bolder;
}

.company-logo {
    position: absolute; /* This will allow us to position the logo relative to the banner */
    top: 50%;           /* Center vertically */
    height: 120px;       /* Adjust as needed */
    transform: translateY(-50%); /* This will perfectly center the logo vertically */
    cursor: pointer;
    background: rgba(220, 225, 227, 0) none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
}

.button-container {
    display: flex;
    margin-left: 100px; /* Adjust this value for desired spacing between logo and buttons */
}
